import React from 'react';
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Button, Offcanvas } from "react-bootstrap";
import { slackLinks } from "../constants";
import { useAuth } from "../contexts/AuthContext"
import { useNavigate } from "react-router-dom"

// IMAGE
import logo from "../logo.svg";
//import slackImg from "../images/slack-small.svg";
import settingIcon from "../images/icons/settings.svg";
import mailIcon from "../images/icons/mail.svg";
import dollorIcon from "../images/icons/dollor.svg";
import userIcon from "../images/icons/user.svg";
import AddToSlackSVG from './SVGIcons/AddToSlackSVG';
//import slackFloating from "../images/slack-floating.svg";

function Header() {
	const { currentUser, logout } = useAuth();
	const navigate = useNavigate();
	const handleLogOut = () => {
		logout();
		navigate("/");
	}
	return (
		<div className="top_header">
			<Navbar expand="md" className='py-md-4'> {/* expand="lg" */}
				<Container>
					<Navbar.Brand href="/">
						<img src={logo} alt="Logo" className="img-fluid" width={140} />
					</Navbar.Brand>
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
					<Navbar.Offcanvas
						id="basic-navbar-nav"
						aria-labelledby={`basic-navbar-nav`}
						placement="end"
					>
						<Offcanvas.Header closeButton></Offcanvas.Header>
						<Offcanvas.Body>
							<Nav className="ms-auto nav_left cm-mobile-menu-wrap">
							<Nav.Link href="/#howitworks">
								<span className="nav_icon">
									<img src={settingIcon} alt="How it Works" width="29px" />
								</span>
								<span className="nav_label">How it Works</span>
							</Nav.Link>
							<Nav.Link href="/#price">
								<span className="nav_icon">
									<img src={dollorIcon} alt="Pricing" width="18.26px" />
								</span>
								<span className="nav_label">Pricing</span>
							</Nav.Link>
							<Nav.Link href="/#contactus">
								<span className="nav_icon">
									<img src={mailIcon} alt="Contact Us" width="25.33px" />
								</span>
								<span className="nav_label">Contact Us</span>
								</Nav.Link>
							<Nav.Link href="/dashboard" className="d-md-none">
								<span className="nav_icon">
									<img src={userIcon} alt="Account" width="25px" />
								</span>
								<span className="nav_label">Account</span>
							</Nav.Link>
						</Nav>
							<Nav className="me-0 nav_right d-none d-md-flex align-items-center">
							<Button href={slackLinks.ADDTOSLACK} className="slack_btn">
								{/* <img src={slackImg} alt="Add to Slack" className="img-fluid d-none" /> */}
								<AddToSlackSVG width="140"/>
							</Button>
							<Button href={currentUser?"/dashboard":"/login"} className="btn-secondary">Account</Button>
							{currentUser &&
								<Button className="btn-secondary text-nowrap" style={{ marginLeft: '10px'}} onClick={handleLogOut}>
									Log Out
								</Button>}
						</Nav>
						</Offcanvas.Body>
					</Navbar.Offcanvas>
					{/* <Navbar.Collapse id="basic-navbar-nav">
						
					</Navbar.Collapse> */}
				</Container>
			</Navbar>
			{/* <Button href={slackLinks.ADDTOSLACK} className="slack_floating d-md-none">
				<img src={slackFloating} alt="Add to slack" className="img-fluid" />
			</Button> */}
		</div>
	);
}
export default Header;

import React, { useEffect, useMemo, useState, useRef } from 'react';
import { Button, Row } from "react-bootstrap";
import {slackLinks} from "../../constants";
import Container from "react-bootstrap/Container";
import bannerImg from "../../images/home-img.png";
//import slackBtn from "../../images/slack-large.svg";
import AddToSlackSVG from '../SVGIcons/AddToSlackSVG';

function useIsInViewport(ref) {
    const [isIntersecting, setIsIntersecting] = useState(false);

    const observer = useMemo(
        () =>
            new IntersectionObserver(([entry]) =>
                setIsIntersecting(entry.isIntersecting),
            ),
        [],
    );
    useEffect(() => {
        observer.observe(ref.current);

        return () => {
            observer.disconnect();
        };
    }, [ref, observer]);

    return isIntersecting;
}

function OnlyOneTimeView(value, setValue, statusVal) {
    if (!statusVal) {
        setValue(value);
    }
    return false;
}
function Banner() {
    const home1 = useRef(null);
    const [isHomeViewport2ct, setisHomeViewport2ct] = useState(false);
    const isHome1Viewport = useIsInViewport(home1);
    useEffect(() => {
        if (isHome1Viewport) {
            OnlyOneTimeView(isHome1Viewport, setisHomeViewport2ct, isHomeViewport2ct);
        }
    }, [isHome1Viewport]);
	return (
        <div className="top_banner" ref={home1}>
			<Container>
				<Row className="align-items-center">
                    <div className="col-xxl-5 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 cm-mb-col-center ">
                        <h1 className={isHomeViewport2ct ? "wow animate__fadeIn animate__animated animate__slow animate__delay-1s" : ""}>A more mindful place to work</h1>
                        <h3 className={isHomeViewport2ct ? "wow animate__fadeIn animate__animated animate__slow animate__delay-1s" : ""}>Take mindful breaks with our <strong>meditation</strong> and <strong>breathwork</strong> courses right in <strong>Slack.</strong></h3>
                        <Button href={slackLinks.ADDTOSLACK} className={isHomeViewport2ct ? "wow animate__fadeIn animate__animated animate__slow animate__delay-1s slack_btn" : "slack_btn"}>
                            {/* <img src={slackBtn} alt="Add to Slack" /> */}
                            <div><AddToSlackSVG width="250"/></div>
                        </Button>
                    </div>
                    <div className="col-xxl-7 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                        <img src={bannerImg} alt="Banner" className={isHomeViewport2ct ? "wow animate__zoomIn animate__animated animate__slow animate__delay-1s img-fluid" : "img-fluid"} />
                        
                    </div>
                </Row>
			</Container>
		</div>
	);
}
export default Banner;

import React, { useEffect } from "react"
import { useAuth } from "../contexts/AuthContext"
import {useParams} from "react-router-dom";
import ReactLoading from "react-loading";
import { fc } from '../firebaseInit';
import {Container } from "react-bootstrap";


export default function SignIn() {
    const { customLogin } = useAuth()
    const params = useParams();
  
    const getToken = async () => {
      let parsedUrl = new URL(window.location.href);
      console.log('sign in ')
      let code = parsedUrl.searchParams.get('code');
      console.log('parsecode');
        const slackSignIn = fc.httpsCallable('slack_sign_in');
        console.log(params);
        console.log('code'+code);
        let result = await slackSignIn({code:code, redirectUri:(process.env.REACT_APP_ENV === "local" ? 'https://localhost:3000/signin': 'https://deskvana.app/signin')});
        console.log(result);
        return result;
    }

    useEffect(()=>{
        getToken().then(

          (token)=>{
            console.log(token.data.data.accessToken);
            customLogin(token.data.data.accessToken).then(()=>
            {
              window.location.href = "/dashboard";
            });
            
          }
        ); 
       
    });
    return (
  
        <Container fluid className="login">
      <ReactLoading type="spinningBubbles" color="#bb6bd9" height={100} width={100}/>
        </Container>
    )
  }
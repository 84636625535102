import React, {  useEffect } from "react"
import { useAuth } from "../contexts/AuthContext"
import { useNavigate } from "react-router-dom"
import ReactLoading from "react-loading";
import { fc } from '../firebaseInit';
import { Container } from "react-bootstrap";


export default function Signup() {
    const { customLogin } = useAuth()
    const navigate = useNavigate();
  
    const getToken = async () => {
      let parsedUrl = new URL(window.location.href);
      let code = parsedUrl.searchParams.get('code');
      console.log(code);
        const slack_auth = fc.httpsCallable('slack_auth');
        return await slack_auth({code:code, redirectUri:process.env.REACT_APP_ENV === "local" ? 'https://localhost:3000/signup': 'https://deskvana.app/signup'});
    }

    useEffect(()=>{
        console.log("I FIRE ONCE");
        getToken().then(
          (token)=>{
            console.log(token);
            customLogin(token.data.data.accessToken).then(()=>
            {
                console.log('NAVIGATE')
                navigate("/dashboard");
            });
            
          }
        ); 
       
    },[]);
    return (
  
        <Container fluid center className="login">
        <ReactLoading type="spinningBubbles" color="#bb6bd9" height={100} width={100}/>
          </Container>
    )
  }
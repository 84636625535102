import React, { useEffect } from 'react'
import "bootstrap/dist/css/bootstrap.min.css";
import WOW from 'wowjs';
import "./App.css";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { AuthProvider } from "./contexts/AuthContext"
import Home from "./screens/Home";
import SignUp from "./screens/SignUp"
import SignIn from "./screens/SignIn"
import Privacy from "./screens/Privacy";
import TermsAndConditions from "./screens/TermsAndConditions";
import Login from "./screens/Login";
import StripePage from "./screens/Stripe";
import Dashboard from "./screens/Dashboard";
import Account from './components/Dashboard/Account';
import Metrics from './components/Dashboard/Metrics';
import Settings from './components/Dashboard/Settings';

function App() {
  useEffect(() => {
    new WOW.WOW({
      live: false
    }).init();
  }, [])
  return (
    <div className="lightTheme">
      <BrowserRouter>
        <AuthProvider>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login/>} />
            <Route path="/dashboard" element={<Dashboard/>}>
              <Route index element={<Navigate to="account" replace />} />
              <Route path="account" element={<Account />} />
              <Route path="metrics" element={<Metrics/>} />
              <Route path="settings" element={<Settings />} />
            </Route>
            <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
            <Route path="/privacy" element={<Privacy/>} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/stripe" element={<StripePage />} />
          </Routes>
          <Footer />
        </AuthProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;

import React, { useEffect, useMemo, useState, useRef } from 'react';
import { Ratio, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import videoThumb from "../../images/video_thumb.png";
function useIsInViewport(ref) {
    const [isIntersecting, setIsIntersecting] = useState(false);

    const observer = useMemo(
        () =>
            new IntersectionObserver(([entry]) =>
                setIsIntersecting(entry.isIntersecting),
            ),
        [],
    );
    useEffect(() => {
        observer.observe(ref.current);

        return () => {
            observer.disconnect();
        };
    }, [ref, observer]);

    return isIntersecting;
}

function OnlyOneTimeView(value, setValue, statusVal) {
    if (!statusVal) {
        setValue(value);
    }
    return false;
}
function VideoSection() {
    const videosec = useRef(null);
    const [isVideoSecViewport2ct, setisVideoSecViewport2ct] = useState(false);
    const isVideoSecViewport = useIsInViewport(videosec);
    useEffect(() => {
        if (isVideoSecViewport) {
            OnlyOneTimeView(isVideoSecViewport, setisVideoSecViewport2ct, isVideoSecViewport2ct);
        }
    }, [isVideoSecViewport]);
    return (
        <div className="video_section" id="howitworks" ref={videosec}>
            <Container>
                <Row className="justify-content-center">
                    <div className="col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12">
                        <Ratio aspectRatio="16x9" className={isVideoSecViewport2ct ? "wow animate__slideInUp animate__animated animate__slow" : ""}>
                            <video width="100%" height="240"  src={"https://storage.googleapis.com/deskvana-public/welcome.mp4"} poster={videoThumb} controls />
                        </Ratio>
                    </div>
                </Row>
            </Container>
        </div>
    );
}
export default VideoSection;

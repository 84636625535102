import React from 'react';
const Settings = () => {
    return (
        <>
        <h1>Settings</h1>
        <div className="inProgress">
         <h2>Coming Soon...</h2>
        </div>
        </>
    )
}

export default Settings
import React ,{useMemo, useEffect, useState, useRef}from "react";
function useIsInViewport(ref) {
    const [isIntersecting, setIsIntersecting] = useState(false);

    const observer = useMemo(
        () =>
            new IntersectionObserver(([entry]) =>
                setIsIntersecting(entry.isIntersecting),
            ),
        [],
    );
    useEffect(() => {
        observer.observe(ref.current);

        return () => {
            observer.disconnect();
        };
    }, [ref, observer]);

    return isIntersecting;
}
function OnlyOneTimeView(value, setValue, statusVal) {
    if (!statusVal) {
        setValue(value);
    }
    return false;
}
function CustomPricing(){
    const [isCustomPriceInfo, seCustomPriceInfo] = useState(false);
    const custompriceInfo = useRef(null);
    const custompriceInfoViewPort = useIsInViewport(custompriceInfo);
    useEffect(() => {
        if (custompriceInfoViewPort) {
            OnlyOneTimeView(custompriceInfoViewPort, seCustomPriceInfo, isCustomPriceInfo);
        }
    }, [custompriceInfoViewPort]);
    return(
    <>
    <div className="custom-pricing-sec" ref={custompriceInfo}>
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <p className={isCustomPriceInfo ? "wow animate__fadeIn animate__animated animate__slow animate__delay-1s cm-custom-price-info" : "cm-custom-price-info"}>
                    *If you are a 501(c)(3) NonProfit, P.O.C/LGBTQ organization, school, or an under-priviledged community, we don’t want the cost of this app to keep you from participating in mindfulness. Please reach out to us so we can work together for custom pricing.
                    </p>
                </div>
            </div>
        </div>
    </div>
    </>
    )
}

export default CustomPricing;
import React, { useEffect, useMemo, useState, useRef } from 'react';
import { Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import resetImg from "../../images/reset-renew-img.svg";

function useIsInViewport(ref) {
    const [isIntersecting, setIsIntersecting] = useState(false);

    const observer = useMemo(
        () =>
            new IntersectionObserver(([entry]) =>
                setIsIntersecting(entry.isIntersecting),
            ),
        [],
    );
    useEffect(() => {
        observer.observe(ref.current);

        return () => {
            observer.disconnect();
        };
    }, [ref, observer]);

    return isIntersecting;
}

function OnlyOneTimeView(value, setValue, statusVal) {
    if (!statusVal) {
        setValue(value);
    }
    return false;
}
function ResetSection() {
    const resetSec = useRef(null);
    const [isResetSecViewport2ct, setisResetSecViewport2ct] = useState(false);
    const isResetSecViewport = useIsInViewport(resetSec);
    useEffect(() => {
        if (isResetSecViewport) {
            OnlyOneTimeView(isResetSecViewport, setisResetSecViewport2ct, isResetSecViewport2ct);
        }
    }, [isResetSecViewport]);
    return (
        <div className="reset_banner" ref={resetSec}>
            <Container>
                <img src={resetImg} alt="Reset. Renew. Recharge" className={isResetSecViewport2ct ? "img-fluid d-block mx-auto reset_img wow animate__zoomIn animate__animated animate__slow animate__delay-1s" : "img-fluid d-block mx-auto reset_img"} />
                <Row className="justify-content-center">
                    <div className="col-xxl-8 col-xl-10 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                        <h2 className={isResetSecViewport2ct ? "wow animate__fadeIn animate__animated animate__slow animate__delay-1s" : ""}>10 minute meditations, soundbaths, and breathwork courses taught by certified experts.</h2>
                        <h5 className={isResetSecViewport2ct ? "wow animate__fadeIn animate__animated animate__slow animate__delay-1s" : ""}>Add to Slack. Select a course. Plug in your headphones and close your eyes. </h5>
                    </div>
                </Row>
            </Container>
        </div>
    );
}
export default ResetSection;

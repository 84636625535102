import React, { useEffect, useMemo, useState, useRef } from 'react';
import { Button, Form, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";

// IMAGE
// import instaIcon from "../images/icons/insta.svg";
// import linkedinIcon from "../images/icons/linkedin.svg";
// import spotifyIcon from "../images/icons/spotify.svg";
import logo from "../logo.svg";
import LinkDinSocial from './SVGIcons/LinkDinSocial';
import InstagramSocial from './SVGIcons/InstagramSocial';
import SpotifySocial from './SVGIcons/SpotifySocial';
function useIsInViewport(ref) {
    const [isIntersecting, setIsIntersecting] = useState(false);

    const observer = useMemo(
        () =>
            new IntersectionObserver(([entry]) =>
                setIsIntersecting(entry.isIntersecting),
            ),
        [],
    );
    useEffect(() => {
        observer.observe(ref.current);

        return () => {
            observer.disconnect();
        };
    }, [ref, observer]);

    return isIntersecting;
}

function OnlyOneTimeView(value, setValue, statusVal) {
    if (!statusVal) {
        setValue(value);
    }
    return false;
}
function Footer() {
    const contactSec = useRef(null);
    const [isContactSecViewport2ct, setisContactSecViewport2ct] = useState(false);
    const isContactSecViewport = useIsInViewport(contactSec);
    useEffect(() => {
        if (isContactSecViewport) {
            OnlyOneTimeView(isContactSecViewport, setisContactSecViewport2ct, isContactSecViewport2ct);
        }
    }, [isContactSecViewport]);
    return (
        <>
            <div className="contact_form" id="contactus" ref={contactSec}>
                <Container>
                    <div className="text-center">
                        <h2 className={isContactSecViewport2ct ? "wow animate__fadeIn animate__animated animate__slow animate__delay-250ms" : ""}>Contact Us</h2>
                        <h5 className={isContactSecViewport2ct ? "wow animate__fadeIn animate__animated animate__slow animate__delay-250ms" : ""}>Any questions or remarks? Just write us a message.</h5>
                    </div>
                    <Row className="justify-content-center">
                        <div className="col-xl-7 col-lg-10 col-md-12 col-sm-12 col-12">
                            <Form action="https://formspree.io/f/xkndvgpr" method="POST" className={isContactSecViewport2ct ? "wow animate__bounceIn animate__animated animate__slow animate__delay-1s" : ""}>
                                <Row className="justify-content-center">
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                        <Form.Group className="mb-md-5 mb-sm-4 mb-3" controlId="email">
                                            <Form.Control type="email" placeholder="Enter a valid email address" name="email"/>
                                        </Form.Group>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                        <Form.Group className="mb-md-5 mb-sm-4 mb-3" controlId="name">
                                            <Form.Control type="text" placeholder="Enter your name" name="name" />
                                        </Form.Group>
                                    </div>
                                </Row>
                                <Button variant="primary" type="submit">
                                    Submit
                                </Button>
                            </Form>
                        </div>
                    </Row>                    
                </Container>
            </div>
            <div className="bottom_footer">
                <Container>
                    <Row className="align-items-center">
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                            <Button className="footer_logo">
                                <img src={logo} alt="Logo" className="img-fluid" width={140} />
                            </Button>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                            <ul className="quicklinks">
                                <li>
                                    <Button href="https://arrow-alley-81a.notion.site/FAQ-75ff263381594ec5bac146d257c1eaef" target="_blank">FAQ</Button>
                                </li>
                                <li>
                                    <Button href="/terms-and-conditions">Terms & Conditions</Button>
                                </li>
                                <li>
                                    <Button href="/privacy">Privacy</Button>
                                </li>
                            </ul>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                            <ul className="footer_social">
                                <li>
                                    <Button href="https://www.linkedin.com/company/deskvana" target="_blank">
                                        {/* <img src={linkedinIcon} className="img-fluid" alt="Linkedin" width={"35px"} /> */}
                                        <LinkDinSocial width="50"/>
                                    </Button>
                                </li>
                                <li>
                                    <Button href="https://open.spotify.com/playlist/1krwOlSO4nxCJosuWvAtPb?si=sYOf1Yw-QxONMabJp9LOTA"target="_blank">
                                        {/* <img src={spotifyIcon} className="img-fluid" alt="Spotify" width={"35px"} /> */}
                                        <SpotifySocial width="50"/>
                                    </Button>
                                </li>
                                <li>
                                    <Button href="https://www.instagram.com/deskvana/"target="_blank">
                                        {/* <img src={instaIcon} className="img-fluid" alt="Instagram" width={"35px"} /> */}
                                        <InstagramSocial width="50"/>
                                    </Button>
                                </li>
                            </ul>
                        </div>
                    </Row>
                </Container>
            </div>
        </>
    );
}
export default Footer;

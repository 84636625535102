import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';

const app = firebase.initializeApp({
  apiKey: "AIzaSyD5fACwEutB7npsOBYcEDZwBcPoCpXXPGc",
  authDomain: "deskvana.firebaseapp.com",
  databaseURL: "https://deskvana-default-rtdb.firebaseio.com",
  projectId: "deskvana",
  storageBucket: "deskvana.appspot.com",
  messagingSenderId: "394474850002",
  appId: "1:394474850002:web:e964b8083820ca10f48f0b",
  measurementId: "G-DKDT7D200X"
})

export const fc = app.functions("us-west2");
export const auth = app.auth();
export const db =  app.firestore();
export default app
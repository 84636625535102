import React, { useEffect, useMemo, useState, useRef } from 'react';
import { Carousel, Nav, Row, Tab } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Package from "./Package";

function useIsInViewport(ref) {
    const [isIntersecting, setIsIntersecting] = useState(false);

    const observer = useMemo(
        () =>
            new IntersectionObserver(([entry]) =>
                setIsIntersecting(entry.isIntersecting),
            ),
        [],
    );
    useEffect(() => {
        observer.observe(ref.current);

        return () => {
            observer.disconnect();
        };
    }, [ref, observer]);

    return isIntersecting;
}

function OnlyOneTimeView(value, setValue, statusVal) {
    if (!statusVal) {
        setValue(value);
    }
    return false;
}
function PriceSection() {
    const planSec = useRef(null);
    const [isPlanSecViewport2ct, setisPlanSecViewport2ct] = useState(false);
    const isPlanSecViewport = useIsInViewport(planSec);
    useEffect(() => {
        if (isPlanSecViewport) {
            OnlyOneTimeView(isPlanSecViewport, setisPlanSecViewport2ct, isPlanSecViewport2ct);
        }
    }, [isPlanSecViewport]);
    const [active_item, setActiveitem] = useState(0);

    const monthlyPlans = [
        {
            id: "0",
            planName: "Starter",
            type: "month",
            price: "5",
            desc: "1-9 users",
        },
        {
            id: "1",
            planName: "Business",
            type: "month",
            price: "4",
            desc: "10-49 users",
        },
        {
            id: "2",
            planName: "Business Plus",
            type: "month",
            price: "3.50",
            desc: "50-99 users",
        },
        {
            id: "3",
            planName: "Enterprise",
            type: "month",
            price: "custom",
            desc: "100+ users",
        },
    ]
    const yearlyPlans = [
        {
            id: "0",
            planName: "Starter",
            type: "yearly",
            price: "50",
            desc: "1-9 users",
        },
        {
            id: "1",
            planName: "Business",
            type: "yearly",
            price: "40",
            desc: "10-50 users",
        },
        {
            id: "2",
            planName: "Business Plus",
            type: "yearly",
            price: "35",
            desc: "50-99 users",
        },
        {
            id: "3",
            planName: "Enterprise",
            type: "yearly",
            price: "custom",
            desc: "100+ users",
        },
    ]

    const checkActive = (index) => {
        return index === active_item
    }

    const handleHoverIn = (index) => {
        setActiveitem(index)
    }
    const handleHoverOut = () => {
        setActiveitem(0)
    }
    return (
        <div className="price_section" id="price" ref={planSec}>
            <Container>
                <Tab.Container id="pricing" defaultActiveKey="monthly">
                    <Row className="align-items-center">
                        <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12">
                            <div className="section_title">
                                <h2 className={isPlanSecViewport2ct ? "wow animate__fadeInUp animate__animated animate__slow animate__delay-1s" : ""}>Plans & Pricing</h2>
                                <h5 className={isPlanSecViewport2ct ? "wow animate__fadeInUp animate__animated  animate__slow animate__delay-1s" : ""}>Whether your business is large or small, we’re here to make your workspace more mindful. Pricing is based per user in your organization’s Slack space.</h5>
                            </div>
                        </div>
                        <div className={isPlanSecViewport2ct ? "wow animate__fadeInDown animate__animated animate__slow animate__delay-1s col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12" : "col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12"}>
                            <Nav variant="pills" className="flex-row justify-content-lg-end">
                                <Nav.Item>
                                    <Nav.Link eventKey="monthly">MONTHLY</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="yearly">YEARLY</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>
                    </Row>
                    <Tab.Content>
                        <Tab.Pane eventKey="monthly">
                            <div className="prices_area">
                                <div className='d-block d-md-none'>
                                    <Carousel className='cm-mb-price-carousel'>
                                        {
                                            monthlyPlans && monthlyPlans?.length > 0 &&
                                            monthlyPlans.map((item, index) => {
                                                let animateName = "";
                                                if (index % 2 == 0) {
                                                    animateName = "animate__fadeInDown";
                                                } else {
                                                    animateName = "animate__fadeInUp";
                                                }
                                                return (
                                                    <Carousel.Item key={item.id} className={isPlanSecViewport2ct ? ` ${animateName} wow  animate__animated animate__slow animate__delay-1s` : ""}>
                                                        <div className='cm-bg-wrap bg-white'></div>
                                                        <Carousel.Caption>
                                                            <Package
                                                                onMouseEnter={(index) => handleHoverIn(index)}
                                                                onMouseLeave={() => handleHoverOut()}
                                                                key={item.id}
                                                                item={item}
                                                                is_active={checkActive(index)}
                                                            />
                                                        </Carousel.Caption>
                                                    </Carousel.Item>
                                                )
                                            })
                                        }

                                    </Carousel>
                                </div>
                                <Row xl={4} className='d-none d-md-flex'>
                                    {
                                        monthlyPlans && monthlyPlans?.length > 0 &&
                                        monthlyPlans.map((item, index) => {
                                            let animateName = "";
                                            if (index % 2 == 0) {
                                                animateName = "animate__fadeInDown wow  animate__animated animate__slow animate__delay-1s";
                                            } else {
                                                animateName = "animate__fadeInUp wow  animate__animated animate__slow animate__delay-1s";
                                            }
                                            return (
                                                <Package
                                                    classNameData={animateName}
                                                    onMouseEnter={(index) => handleHoverIn(index)}
                                                    onMouseLeave={() => handleHoverOut()}
                                                    key={item.id}
                                                    item={item}
                                                    is_active={checkActive(index)}
                                                />
                                            )
                                        })
                                    }
                                </Row>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="yearly">
                            <div className="prices_area">
                                <div className='d-block d-md-none'>
                                    <Carousel className='cm-mb-price-carousel'>
                                        {
                                            yearlyPlans && yearlyPlans?.length > 0 &&
                                            yearlyPlans.map((item, index) => {
                                                return (
                                                    <Carousel.Item key={item.id}>
                                                        <div className='cm-bg-wrap bg-white'></div>
                                                        <Carousel.Caption>
                                                            <Package
                                                                onMouseEnter={(index) => handleHoverIn(index)}
                                                                onMouseLeave={() => handleHoverOut()}
                                                                key={item.id}
                                                                item={item}
                                                                yearly={true}
                                                                is_active={checkActive(index)}
                                                            />
                                                        </Carousel.Caption>
                                                    </Carousel.Item>
                                                )
                                            })
                                        }
                                    </Carousel>
                                </div>
                                <Row xl={4} className='d-none d-md-flex'>
                                    {
                                        yearlyPlans && yearlyPlans?.length > 0 &&
                                        yearlyPlans.map((item, index) => {
                                            let animateName = "";
                                            if (index % 2 == 0) {
                                                animateName = "animate__fadeInDown wow  animate__animated animate__slow animate__delay-1s";
                                            } else {
                                                animateName = "animate__fadeInUp wow  animate__animated animate__slow animate__delay-1s";
                                            }
                                            return (
                                                <Package
                                                    classNameData={animateName}
                                                    onMouseEnter={(index) => handleHoverIn(index)}
                                                    onMouseLeave={() => handleHoverOut()}
                                                    key={item.id}
                                                    item={item}
                                                    yearly={true}
                                                    is_active={checkActive(index)}
                                                />
                                            )
                                        })
                                    }
                                </Row>
                            </div>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </Container>
        </div>
    );
}
export default PriceSection;

import React, { useEffect, useState } from "react"
import { Button, } from "react-bootstrap"
import { loadStripe } from '@stripe/stripe-js';
import "firebase/functions";
import { fc } from '../firebaseInit';
import { useAuth } from "../contexts/AuthContext"
import { db } from "../firebaseInit"
import ReactLoading from "react-loading";
import {Container } from "react-bootstrap";
import 'firebase/database'

const stripePromise = loadStripe('pk_live_51JOfJ2HuH1ms6oOyjybWawkrVZkac3qhXrX42Puqe4aJ23QGmeHRhH84Hfas15OiRzSqzpO6kAzFod1zF9EodH2q00i6C2iQkE');




const loadPortal = async () => {
  const functionRef = fc.httpsCallable("ext-firestore-stripe-subscriptions-createPortalLink");
  const { data } = await functionRef({ returnUrl: process.env.REACT_APP_ENV === "local" ? 'https://localhost:3000/dashboard': 'https://deskvana.app/dashboard' })
  window.location.href = data.url;
}



export default function StripePage() {
  const [subs, setSubs] = useState([]);
  const { currentUser, product} = useAuth();
  const [monthSelected, setMonthSelected] = useState(true);
  const [, setLoggedIn] = useState(false);
  const [, setLoading] = useState(true);
  const loadCheckout = async (currentUser, priceType) => {
    console.log(await currentUser.getIdTokenResult());
    const stripe = await stripePromise;
    //TODO PUSH THIS TO BACK END
    const docRef = await db
      .collection("customers")
      .doc(currentUser.uid)
      .collection("checkout_sessions")
      .add({
        price: product[priceType].id,
        allow_promotion_codes: true,
        success_url: window.location.origin,
        cancel_url: window.location.origin
      });

    // Wait for the CheckoutSession to get attached by the extension
    docRef.onSnapshot((snap) => {
      const { sessionId } = snap.data();
      console.log('SESSION', snap.data());
      if (sessionId) {
        // We have a session, let's redirect to Checkout
        stripe.redirectToCheckout({ sessionId });
      }
    });

  }


  useEffect(
    () => {
      console.log(product);
      if (currentUser) {
        db.collection("customers").doc(currentUser.uid).collection("subscriptions").get().then((subDoc) => {
          const docArray = subDoc.docs.map(doc => doc.data())
          setSubs(docArray);
          setLoggedIn(true);
        });
      }
      else {
        setLoggedIn(false);
        setLoading(false);
      }
    },
    []
  )
  console.log(product);
  return (
    !product?.tierName ?
      (<Container fluid>
        <ReactLoading type="spinningBubbles" color="#bb6bd9" height={100} width={100} />
      </Container>) :
      <div>
        <div className="col-sm-7">
          <div className="card mb-5 mb-lg-0">
            <div className="stripe-card">
              <h5 className="card-title text-uppercase text-center">
                {product.tierName}
              </h5>
              <h6 className="card-price text-center"></h6>
              <ul className="fa-ul">
                <li >
                  <span className="fa-li"></span>
                  Active Slack Users: {product.activeUsers}
                </li>
                <li >
                  <span className="fa-li"></span>
                  Subscription Tier: {product.tierLabel}
                </li>

              </ul>
              <hr />
              <ul className="fa-ul">
                <li className="text-muted">
                  <span className="fa-li"></span
                  >Classes for Your Entire Company
                </li>
                <li className="text-muted">
                  <span className="fa-li"></span
                  >Annual Contract Options
                </li>
              </ul>
              <div >
                <hr />
                {subs.length === 0 ?
                  <div className="center">
                    <div className={'purchaseSection'}>
                      <div className={`purchasePrice `}>
                        <Button variant='info' className={` rounded ${monthSelected ? "selected" : ""}`} onClick={() => {
                          setMonthSelected(true);
                        }
                        }>
                          <h6 className="text-center">
                            Monthly
                          </h6>
                          <ul><li>Unlimited Courses</li><li>Daily Reminders</li><li>Daily Check-Ins</li></ul>
                          <span className="text-center fs-5">
                            {`$${product.monthPrice.data.unit_amount / 100}`}
                          </span>
                          <span className="text-center" style={{ fontSize: ".7rem" }}>
                            /month
                          </span>
                        </Button>
                      </div>
                      <div className={`purchasePrice`}>
                        <Button className={`rounded ${!monthSelected ? "selected" : ""}`} onClick={() => {
                          setMonthSelected(false)
                        }
                        }>
                          <h6 className="text-center">
                            Yearly
                          </h6>
                          <ul><li>Unlimited Courses</li><li>Daily Reminders</li><li>Daily Check-Ins</li></ul>
                          <span className="text-center fs-5">
                            {`$${(product.yearPrice.data.unit_amount / 100) / 12}`}
                          </span>
                          <span className="text-center" style={{ fontSize: ".7rem" }}>
                            /month
                          </span>
                        </Button>
                      </div>
                    </div>
                    <Button className=" mt-5 btn-secondary purchaseButton" onClick={async () => {
                      loadCheckout(currentUser, monthSelected ? "monthPrice" : "yearPrice");
                    }
                    }>
                      Purchase
                    </Button>
                  </div>
                  :
                  subs.length !== 0 && (
                  <Button className="btn-secondary" style={{ marginLeft: 'auto', marginRight: 'auto' }} onClick={async () => {
                    loadPortal();
                  }
                  }>
                    Manage
                  </Button>)
                }
              </div>
            </div>
          </div>
        </div>



      </div>
  );
}
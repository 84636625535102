import React from 'react';
import { Button } from "react-bootstrap";
import { slackLinks } from "../../constants";


function Package({ item, is_active, onMouseEnter, onMouseLeave, yearly = false, classNameData }) {
    return (
        <div className={`col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 mb-3 ${classNameData}`}>
            <div className={`package_item ${is_active ? "active" : ""}`} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                <h2>${item?.price} <small>/{item?.type}</small></h2>
                <h3>{item?.planName}</h3>
                <p>{item?.desc}</p>
                <ul>
                    <li>Unlimited Courses</li>
                    <li>Daily Reminders</li>
                    <li>Daily Check-Ins</li>
                </ul>
                <Button href={yearly?slackLinks.ADDTOSLACKYEARLY:slackLinks.ADDTOSLACK}>Choose Plan</Button>
            </div>
        </div>
    );
}
export default Package;

import React from 'react';
import '../App.css';
import {IFRAMES} from '../constants';

const TermsAndConditions = () => {
return(
<iframe title='TermsAndConditions' className='page-iframe' src={IFRAMES.TERMSANDCONDITIONS}/>
);
}

export default TermsAndConditions
import React from 'react';
import StripePage from '../../screens/Stripe'
const Account = () => {
    return (
        <>
        <h1>Account</h1>
        <StripePage/>
        </>
    )
}

export default Account
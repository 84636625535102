import React from 'react';
import Banner from "../components/Home/Banner";
import ResetSection from "../components/Home/Reset";
import VideoSection from "../components/Home/Video";
import PriceSection from "../components/Home/Plans";
import HowItWorks from "../components/Home/HowItWorks";
import CustomPricing from '../components/Home/CustomPricing';

const Home =()=> {
    return (
        <>
            <Banner />
            <VideoSection />
            <HowItWorks/>
            <PriceSection />
            <CustomPricing/>
            <ResetSection />
        </>
    );
}

export default Home
import React from 'react';
import { PersonBadge, PieChartFill, Wrench } from 'react-bootstrap-icons';
import { NavLink } from 'react-router-dom';


export const DashboardMenu = () => {
    const menuIcons = [
        { image: <PersonBadge color="#fff" size={27} />, route: 'account'},
        { image: <PieChartFill color="#fff" size={27} />, route: 'metrics' },
        { image: <Wrench color="#fff" size={27} />, route: 'settings' },
    ]
    return (
        <div className={'sidebar'}>
            {menuIcons.map(
                icon => <DashboardIcon key={icon.route} image={icon.image} route={icon.route} />
            )}
        </div>
    );
}

export const DashboardIcon = ({ image, route }) => {
    return (
        <NavLink
            to={route}
            className={"dashboard-button"}>
            {image}
        </NavLink>
    )
}
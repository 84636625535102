import React from 'react';
import '../App.css';
import { DashboardMenu } from '../components/Dashboard/DashboardMenu';
import { Col, Row, Container } from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { Navigate } from 'react-router-dom'
import {
    Outlet
} from "react-router-dom";

const Dashboard = () => {
const { currentUser} = useAuth();
    return (
        currentUser
        ?
        <Container fluid className="dashboard">
            <Row className="h-100">
                <Col sm={2} className="text-center pt-1"><DashboardMenu /></Col>
                <Col>
                    <Outlet/>
                </Col>
            </Row>
        </Container>:
        <Navigate to="/login"/>
    );
}

export default Dashboard
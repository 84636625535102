export const slackLinks=
{
    ADDTOSLACK:"https://slack.com/oauth/v2/authorize?client_id=1890957827456.1879775107761&scope=files:write,remote_files:write,remote_files:share,chat:write,im:history,commands,users:read&user_scope=users:read&redirect_uri="+(process.env.REACT_APP_ENV === "local" ? 'https://localhost:3000/signup': 'https://deskvana.app/signup'),
    ADDTOSLACKYEARLY:"https://slack.com/oauth/v2/authorize?client_id=1890957827456.1879775107761&scope=files:write,remote_files:write,remote_files:share,chat:write,im:history,commands,users:read&user_scope=users:read&redirect_uri="+(process.env.REACT_APP_ENV === "local" ? 'https://localhost:3000/signup': 'https://deskvana.app/signup'),
    SIGNINSLACK:"https://slack.com/openid/connect/authorize?scope=openid&response_type=code&redirect_uri="+(process.env.REACT_APP_ENV === "local" ? 'https://localhost:3000/signin': 'https://deskvana.app/signin')+"&client_id=1890957827456.1879775107761"
}


/*

https://testdesk3.slack.com/oauth?client_id=33527821013.35732171909&scope=chat:write,channels:history,channels:read,channels:join,groups:history,groups:read,mpim:history,mpim:read,mpim:write,im:history,im:read,im:write,reactions:read,users:read,users:read.email,team:read,commands,channels:manage,chat:write.public&user_scope=&redirect_uri=https://app.donut.ai/users/auth/add_to_slack/callback&state=572eba590630603de30810e57c99b5ffb3b40cb44120bee3&granular_bot_scope=1&single_channel=0&install_redirect=&tracked=1&response_type=code&team=

NEEDED SCOPES:
files:write,remote_files:share,chat:write,im:history,commands&user_scope=users:read

chat:write,channels:history,
channels:read,
channels:join,groups:history,groups:read,mpim:history,mpim:read,mpim:write,im:history,im:read,im:write,reactions:read,users:read,users:read.email,team:read,commands,channels:manage,chat:write.public

FOR:
app.client.files.remote.add
client.chat.postMessage
client.views.publish
client.files.remote.update
client.files.remote.share
client.conversations.open
client.conversations.history
client.chat.delete

*/

export const IFRAMES ={
    'TERMSANDCONDITIONS': "https://app.termly.io/document/terms-and-conditions/4ec0526f-5ed2-42c6-a93c-46042a32d083",
    'PRIVACY': 'https://app.termly.io/document/privacy-policy/f4a0a53b-a1d8-4788-bd3a-7b41963f65dc',
    'FAQ': 'https://arrow-alley-81a.notion.site/FAQ-75ff263381594ec5bac146d257c1eaef',
}

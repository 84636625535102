import React, { useEffect, useMemo, useState, useRef } from 'react';
import { Row, Accordion, AccordionContext } from "react-bootstrap";
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Container from "react-bootstrap/Container";
import Card from 'react-bootstrap/Card';
import AddIcon from "../../images/add-icon.png";
import RemoveIcon from "../../images/remove-icon.png";
import { useContext } from "react";
function useIsInViewport(ref) {
    const [isIntersecting, setIsIntersecting] = useState(false);

    const observer = useMemo(
        () =>
            new IntersectionObserver(([entry]) =>
                setIsIntersecting(entry.isIntersecting),
            ),
        [],
    );
    useEffect(() => {
        observer.observe(ref.current);

        return () => {
            observer.disconnect();
        };
    }, [ref, observer]);

    return isIntersecting;
}

function OnlyOneTimeView(value, setValue, statusVal) {
    if (!statusVal) {
        setValue(value);
    }
    return false;
}
const CustomAccordinTitle = ({ children, eventKey,callback})=> {
    const { activeEventKey } = useContext(AccordionContext);
  
    const decoratedOnClick = useAccordionButton(
      eventKey,
      () => callback && callback(eventKey),
    );
  
    const isCurrentEventKey = activeEventKey === eventKey;
    return (
        <Card.Header onClick={decoratedOnClick} className="position-relative cm-according-head bg-white px-0 py-3">
        {children}
        {isCurrentEventKey? (<img  src={RemoveIcon} width="30" alt="Open" className="img-fluid cm-according-img"/>):(<img  src={AddIcon} width="30" alt="Open" className="img-fluid cm-according-img"/>)}
      </Card.Header>
    );
  }
const HowItWorks = ()=>{
    const howItWorks = useRef(null);
    const [isisHowItWorksViewport2ct, setisisHowItWorksViewport2ct] = useState(false);
    const isHowItWorksViewport = useIsInViewport(howItWorks);
    useEffect(() => {
        if (isHowItWorksViewport) {
            OnlyOneTimeView(isHowItWorksViewport, setisisHowItWorksViewport2ct, isisHowItWorksViewport2ct);
        }
    }, [isHowItWorksViewport]);
    const accordinData = [
        {
            title:"What happens when I add Deskvana to Slack?",
            body:"Don’t worry, we take privacy very seriously. Only your designated Slack workspace admin will be able to grant Deskvana access to your workpace! First, click on the Add to Slack button. This will take you to sign in through Slack (If you belong to multiple Slack workspaces the page may ask you to select a Slack team.) The Slack sign-up process will also ask you to give Deskvana permission to access Slack. (Deskvana won’t message anyone without your permission!) That’s it, you’re signed up!"
        },
        {
            title:"How do I select a meditation or breathwork course?",
            body:"Once Deskvana has been added to your Slack workspace, you will be able to access the app Home page from your side navigation bar. The Home menu will display all available courses you can select from. You can also access the course menu at any time by using our Slack shortcut command /breathe."
        },
        {
            title:"What happens when I select the class I want to take?",
            body:"When you select a course, from either the home menu or the shortcut menu, you will see that the course video will be delivered in the Deskvana App messages tab."
        },

    ]
    return (<div className="price_section pb-3" ref={howItWorks}>
    <Container>
        <Row className="align-items-center">
            <div className="col-xl-6 col-lg-7 col-md-12 col-sm-12 col-12">
                <div className="section_title">
                        <h2 className={isisHowItWorksViewport2ct ? "wow animate__fadeInUp animate__animated animate__slow animate__delay-250ms" : ""}>How It Works</h2>
                        <h5 className={isisHowItWorksViewport2ct ? "wow animate__fadeInUp animate__animated animate__slow animate__delay-250ms" : ""}>Deskvana uses science-backed breathing techniques and meditation methods to <strong>reduce stress, increase productivity, and enhance focus.</strong></h5>
                </div>
            </div>
            <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
                
            </div>
        </Row>
        <Row className="justify-content-center">
            <div className="col-md-12">
                <div className="my-4 px-2 px-md-5">
                <Accordion defaultActiveKey="0">
                            {accordinData.map((item, index) => {
                                return (
                                    <Card className={isisHowItWorksViewport2ct ? ` animate__delay-250ms animate__fadeIn wow  animate__animated animate__slow mb-4 border-0` : "mb-4 border-0"} key={`accordind-items-${index}`}>
                                        <CustomAccordinTitle eventKey={index}>{item.title}</CustomAccordinTitle>
                                        <Accordion.Collapse eventKey={index}>
                                            <Card.Body>{item.body}</Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                )
                            })}
                </Accordion>
                </div>
            </div>
        </Row>
    </Container>
</div>)
}
export default HowItWorks

import React from 'react';
const Metrics = () => {
    return (
        <>
        <h1>Metrics</h1>
        <div className="inProgress">
         <h2>Coming Soon...</h2>
        </div>
        </>
    )
}

export default Metrics
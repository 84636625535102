import React from 'react';
import '../App.css';
import {IFRAMES} from '../constants';

const Privacy = () => {
return(
  <iframe title='Privacy' className='page-iframe' src={IFRAMES.PRIVACY}/>
);
}

export default Privacy

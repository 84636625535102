import React, { useContext, useState, useEffect } from "react"
import {signInWithCustomToken } from "firebase/auth"
import { auth,fc } from "../firebaseInit"

const AuthContext = React.createContext()

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState()
  const [product, setProduct] = useState();
  const [loading, setLoading] = useState(true)

  function signup(email, password) {
    return auth.createUserWithEmailAndPassword(email, password)
  }



  function login(email, password) {
    return auth.signInWithEmailAndPassword(email, password)
  }

  function customLogin(token) {

    return signInWithCustomToken(auth,token);

  }

  function logout() {
    return auth.signOut()
  }

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email)
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email)
  }

  function updatePassword(password) {
    return currentUser.updatePassword(password)
  }

  async function loadProduct(){
    console.log('loadingProduct')
    const getUsers = fc.httpsCallable("get_number_users");
    let response = await getUsers({teamId:currentUser.uid});
    let {activeUsers,name,description,prices} = response.data;
    //Add get price based on
    setProduct({activeUsers,tierLabel:name, tierName:description, monthPrice:prices[0], yearPrice:prices[1]});
    console.log('finish loading', product);
  } 

  

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user)
      setLoading(false)
    })
    return unsubscribe
  }, [])

  useEffect(() => {
    if(currentUser?.uid)
    loadProduct();
  }, [currentUser?.uid])

  const value = {
    currentUser,
    product,
    loadProduct,
    login,
    customLogin,
    signup,
    logout,
    resetPassword,
    updateEmail,
    updatePassword
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}